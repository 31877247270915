import React, { useContext, useEffect, useState, useRef } from 'react';

import { LogoutIcon, CogIcon } from '@heroicons/react/outline';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { populateLocalStorageStateWithCalendarEvents } from 'src/api';
import { AppContext } from 'src/App';
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import HabitsView from 'src/modules/HabitsView';
import MacroView from 'src/modules/MacroView';
import { useGetEventsQuery } from 'src/services';

function Home() {
  const [showCogMenu, setShowCogMenu] = useState(false);
  const cogRef = useRef(null);
  let navigate = useNavigate();

  const { data: calendarEvents, isLoading: isCalendarEventsLoading } =
    useGetEventsQuery(null);

  const { state: localStorageState, setState: setLocalStorageState } =
    useContext(AppContext);

  useOnClickOutside(cogRef, () => setShowCogMenu(false));

  useEffect(() => {
    document.title = 'Inbox';
  }, []);

  useEffect(() => {
    if (!isCalendarEventsLoading) {
      populateLocalStorageStateWithCalendarEvents({ calendarEvents })(
        localStorageState,
        setLocalStorageState,
      );
    }
  }, [calendarEvents, isCalendarEventsLoading]);

  const todaysDateFormatted = dayjs().format('dddd, MMMM DD');

  return (
    <div>
      <div className="flex flex-row justify-between items-center text-neutral-400">
        <span className="font-bold">{todaysDateFormatted}</span>
        <div
          ref={cogRef}
          onClick={() => setShowCogMenu(!showCogMenu)}
          className="relative p-2 cursor-pointer">
          <CogIcon className="w-5 h-5 hover:text-neutral-200 hover:bg-zinc-800 rounded-md cursor-pointer" />
          {showCogMenu && (
            <div
              className="
                  grid absolute top-6 left-0 right-0 z-10 grid-cols-1 m-0 w-auto min-w-max mt-2
                  text-xs text-left bg-clip-padding bg-zinc-900 rounded border 
                  border-zinc-800 divide-y divide-zinc-800 divide-dashed shadow-lg">
              <span className="py-2 px-4 hover:text-neutral-200">Settings</span>
              <span
                className="py-2 px-4 hover:text-neutral-200"
                onClick={() => navigate('/logout')}>
                Log Out
              </span>
            </div>
          )}
        </div>
      </div>
      <HabitsView />
      <div className="my-8 w-full h-px bg-zinc-700" />
      <MacroView />
    </div>
  );
}

export default Home;
