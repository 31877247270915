import React from 'react';

import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

function ProtectedRoute({ children: Component, ...otherProps }) {
  const isAuthenticated = useSelector(
    (state) =>
      state.user.isAuthenticated ||
      window.sessionStorage.getItem('isAuthenticated'),
  );
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return Component;
}

export default ProtectedRoute;
