import { createSlice } from '@reduxjs/toolkit';
import { api } from 'src/services';

const initialState = {
  list: [],
  sortPreference: [],
};

const sortList = (list) => {
  return list.sort((a, b) => {
    return a.order < b.order ? -1 : 1;
  });
};

export const habitsSlice = createSlice({
  name: 'habits',
  initialState,
  reducers: {
    reorderHabits(state, action) {
      const { list } = state;
      const { sourceIndex, destinationIndex } = action.payload;

      const [removed] = list.splice(sourceIndex, 1);
      list.splice(destinationIndex, 0, removed);

      state.list = list;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.getHabitsForUser.matchFulfilled,
      (state, { payload }) => {
        const sortedList = sortList(payload);
        state.list = sortedList;
      },
    );

    builder.addMatcher(
      api.endpoints.createHabit.matchFulfilled,
      (state, { payload }) => {
        state.list = state.list.concat(payload);
      },
    );

    builder.addMatcher(
      api.endpoints.updateHabit.matchFulfilled,
      (state, { payload }) => {
        const [updatedHabit] = payload;
        const { id, name, frequency, color } = updatedHabit;
        const originalHabitIndex = state.list.findIndex(
          (habit) => habit.id === id,
        );

        if (originalHabitIndex !== -1) {
          state.list[originalHabitIndex] = {
            name,
            frequency,
            color,
          };
        }
      },
    );

    builder.addMatcher(
      api.endpoints.deleteHabit.matchFulfilled,
      (state, { meta }) => {
        const { id } = meta.arg.originalArgs;
        const deletedHabitIndex = state.list.findIndex(
          (habit) => habit.id === id,
        );

        if (deletedHabitIndex !== -1) {
          state.list.splice(deletedHabitIndex, 1);
        }
      },
    );
  },
});

export const { reorderHabits } = habitsSlice.actions;

export default habitsSlice.reducer;
