import dayjs from 'dayjs';

export const isDevModeEnabled = () => {
  return window.sessionStorage.getItem('devMode') === 'true';
};

export const frequencyToFormattedString = (frequency) => {
  // Only 1x a day goals are supported right now
  if (!Number.isInteger(frequency) || frequency > 7 || frequency <= 0) {
    console.warn(
      `frequencyToFormattedString: ${frequency} is not a valid frequency`,
    );
    return '';
  }

  if (frequency === 7) {
    return 'Everyday';
  }

  return `${frequency}x a week`;
};

export const dateTimeToWeekdayString = (dateTime, abbreviation = 'ddd') =>
  dayjs(dateTime).format(abbreviation);

export const dateTimeToDayDigitString = (dateTime) =>
  dayjs(dateTime).format('DD');

export const getWeekDates = (startDate) => {
  if (startDate && typeof startDate !== 'string') {
    console.error('startDate is not a string');
  }

  // If no start date is provided, use Sunday as the start date
  const today = startDate ? dayjs(startDate) : dayjs().day(0);
  const week = [];

  for (let i = 0; i < 7; i++) {
    week.push(today.add(i, 'day').format('YYYY-MM-DD'));
  }

  return week;
};
