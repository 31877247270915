import axios from 'axios';
import { Service } from 'axios-middleware';

const service = new Service(axios);

service.register({
  onRequest(config) {
    // Construct API url
    config.url = process.env.REACT_APP_API_URL + config.url;
    return config;
  },
});

// Send back cookies on every request
axios.defaults.withCredentials = true;

export default axios;
