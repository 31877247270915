import { useContext } from 'react';

import { AppContext } from 'src/App';

function useAppContext() {
  const context = useContext(AppContext);
  return context;
}

export default useAppContext;
