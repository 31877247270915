import React, { useRef, useState } from 'react';

import { CheckIcon } from '@heroicons/react/outline';
import { BG_COLORS_700, BORDER_COLORS_900 } from 'src/constants/colors';
import useAppContext from 'src/hooks/useAppContext';
import { useCreateHabitMutation, useUpdateHabitMutation } from 'src/services';

import validation, { ValidationErrorMessage } from './validation';

const DEFAULT_COLOR_PREFERENCE = 'green';

function CreateHabitForm({
  mode = 'create',
  existingName,
  existingFrequency,
  existingColor,
  id = null,
  onCancel,
  order,
}) {
  const [createHabit] = useCreateHabitMutation();
  const [updateHabit] = useUpdateHabitMutation();
  const [colorPreference, setColorPreference] = useState(
    existingColor || DEFAULT_COLOR_PREFERENCE,
  );
  const [validationErrors, setValidationErrors] = useState({});
  const nameRef = useRef();
  const frequencyRef = useRef(null);

  /* Used for actions */
  const AppState = useAppContext();
  const { state, setState } = AppState;

  /* Shared classnames */
  const inputClassName =
    'py-1 px-2 my-2 rounded-md bg-zinc-900 border border-zinc-700 placeholder-zinc-500 outline-none';
  const labelClassName = 'text-xs text-zinc-500';

  const hideFormAndClearInputs = () => {
    setColorPreference(DEFAULT_COLOR_PREFERENCE);
    nameRef.current.value = '';
    frequencyRef.current.value = 1;

    onCancel();
  };

  const clearValidationErrors = (e) => {
    const inputName = e.target.name;
    if (!validationErrors[inputName]) return;

    const newValidationErrors = { ...validationErrors };
    delete newValidationErrors[inputName];

    setValidationErrors(newValidationErrors);
  };

  const submitForm = (e) => {
    e.preventDefault();

    const name = nameRef.current.value;
    const frequency = frequencyRef.current.value;

    const checkValidation = validation({ name, frequency });
    setValidationErrors(checkValidation);

    if (Object.keys(checkValidation).length > 0) {
      return;
    }

    const payload = {
      id,
      name,
      frequency,
      color: colorPreference,
      order,
    };

    if (mode === 'create') {
      createHabit(payload);
    } else if (mode === 'edit') {
      updateHabit(payload);
    }
    hideFormAndClearInputs();
  };

  const text = {
    create: {
      title: 'New habit',
      button: 'Add',
    },
    edit: {
      title: 'Edit habit',
      button: 'Save',
    },
  };

  return (
    <div className="p-5 my-4 bg-zinc-800 rounded-lg">
      <h2 className="font-semibold">{text[mode].title}</h2>
      <form className="my-2" onSubmit={submitForm}>
        <div className="flex flex-row">
          <div className="flex flex-col">
            <label className={labelClassName}>Name</label>
            <input
              name="name"
              placeholder="Example"
              className={inputClassName}
              ref={nameRef}
              onChange={clearValidationErrors}
              defaultValue={existingName}
            />
            {validationErrors.name && (
              <ValidationErrorMessage message={validationErrors.name} />
            )}
          </div>
          <div className="flex flex-col ml-10">
            <label className={labelClassName}>Frequency</label>
            <div>
              <input
                type="number"
                max="7"
                min="1"
                name="frequency"
                placeholder="0"
                className={`${inputClassName} w-12 mr-2 text-center`}
                ref={frequencyRef}
                onChange={clearValidationErrors}
                defaultValue={existingFrequency}
              />
              <span className="text-zinc-500">day(s) a week</span>
            </div>
            {validationErrors.frequency && (
              <ValidationErrorMessage message={validationErrors.frequency} />
            )}
          </div>
        </div>
        <div>
          <label className={labelClassName}>Color</label>
          <div className="flex flex-row mt-1">
            {Object.keys(BG_COLORS_700).map((color, index) => {
              // Hide zinc-700 color
              if (color === 'zinc') return;

              const selectedColor = colorPreference === color;
              return (
                <div
                  key={index}
                  className={`${BG_COLORS_700[color]} ${
                    index !== 0 ? 'ml-4' : null
                  } ${
                    selectedColor
                      ? `border-2 ${BORDER_COLORS_900[color]}`
                      : null
                  } rounded-full w-8 h-8 cursor-pointer flex justify-center items-center`}
                  onClick={() => setColorPreference(color)}>
                  {selectedColor && <CheckIcon className="w-4 h-4" />}
                </div>
              );
            })}
          </div>
        </div>
        <div
          name="form-actions"
          className="flex justify-end items-center mt-4 text-xs">
          <span
            onClick={hideFormAndClearInputs}
            className="text-zinc-500 hover:underline cursor-pointer">
            Cancel
          </span>
          <button
            type="submit"
            className="py-1 px-4 ml-4 text-white bg-green-700 rounded-sm">
            {text[mode].button}
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreateHabitForm;
