import React, { useState, useRef, useEffect } from 'react';

import {
  CalendarIcon,
  DotsHorizontalIcon,
  CheckIcon,
} from '@heroicons/react/outline';
import { useSelector } from 'react-redux';
import {
  deleteHabit as deleteHabitFromLocalStorage,
  removeHabitFromCalendar,
  checkHabit,
  uncheckHabit,
} from 'src/api/actions';
import CreateHabitForm from 'src/components/CreateHabitForm';
import Popup from 'src/components/Popup';
import { BG_COLORS_700 } from 'src/constants/colors';
import {
  dateTimeToDayDigitString,
  dateTimeToWeekdayString,
  frequencyToFormattedString,
} from 'src/helpers';
import { isDevModeEnabled } from 'src/helpers';
import useAppContext from 'src/hooks/useAppContext';
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import { useDeleteHabitMutation } from 'src/services';
import { useCreateEventMutation, useDeleteEventMutation } from 'src/services';

function Habit({ innerRef, provided, style, habit = {}, weekData = [] }) {
  const [showSettings, setShowSettings] = useState(false);
  const settingsRef = useRef(null);

  const [inEditMode, setInEditMode] = useState(false);

  const [showDeletionConfirmationPopup, setShowDeletionConfirmationPopup] =
    useState(false);
  const [hovered, setHovered] = useState([false, null]);
  const [recentlyChecked, setRecentlyChecked] = useState([false, null]);

  // Store
  const habits = useSelector((state) => state.habits.list);

  // Requests
  const [deleteHabit] = useDeleteHabitMutation();
  const [createEvent] = useCreateEventMutation();
  const [deleteEvent] = useDeleteEventMutation();

  useEffect(() => {
    if (recentlyChecked[0]) {
      setTimeout(() => {
        setRecentlyChecked([false, null]);
      }, 1000);
    }
  }, [recentlyChecked]);

  const { id, name = 'Example', color = 'green', frequency = 1, order } = habit;

  const completion = weekData.reduce((acc, [date, done]) => acc + done, 0);
  const completionPercentage = Math.round((completion / frequency) * 100);

  const AppState = useAppContext();
  const { state, setState } = AppState;

  const toggleCheckHabit = ({ id, date, isChecked }) => {
    if (isChecked) {
      deleteEvent({ id, date });
      return uncheckHabit({ id, date })(state, setState);
    }

    setRecentlyChecked([true, date]);

    createEvent({ id, date });
    return checkHabit({ id, date })(state, setState);
  };

  useOnClickOutside(settingsRef, () => setShowSettings(false));

  const deleteHabitConfirmation = (habitId) => {
    deleteHabit({ id: habitId });
    removeHabitFromCalendar({ id: habitId })(state, setState);
    setShowDeletionConfirmationPopup(false);
  };

  if (inEditMode) {
    return (
      <CreateHabitForm
        mode="edit"
        onCancel={() => setInEditMode(false)}
        existingFrequency={frequency}
        existingName={name}
        existingColor={color}
        id={id}
        order={order}
      />
    );
  }

  const draggableProps = {
    ...provided.draggableProps,
    ...provided.dragHandleProps,
    ref: innerRef,
    style,
  };

  return (
    <div
      {...draggableProps}
      className="relative p-4 my-4 bg-zinc-800 rounded-lg">
      {/* <div className="absolute w-full h-1 top-0 left-0 bg-neutral-500 rounded-t-lg" /> */}
      <div className="flex flex-row justify-between">
        <div
          name="habit"
          className="inline-flex justify-start items-center font-semibold truncate w-xxs">
          <span>{name}</span>
        </div>
        <div name="frequency" className="inline text-sm text-zinc-400">
          {frequencyToFormattedString(frequency)}
        </div>
      </div>
      <div name="week" className="flex flex-row justify-between my-4">
        {weekData.map((day, index) => {
          const dayName = dateTimeToWeekdayString(day[0]);
          const dayNumber = dateTimeToDayDigitString(day[0]);
          const isChecked = day[1];
          const isToday = dayNumber === String(new Date().getDate());
          const shouldAddHoverState =
            hovered && index === hovered[1] && !isChecked;

          const renderValue = () => {
            const isRecentlyChecked =
              recentlyChecked[0] && day[0] === recentlyChecked[1];

            if (shouldAddHoverState || isRecentlyChecked) {
              return (
                <CheckIcon
                  className={`w-4 h-4 ${
                    isRecentlyChecked ? 'animate-scale-up' : null
                  }`}
                />
              );
            }

            return dayNumber;
          };

          return (
            <div key={index} className="inline-block text-center text-zinc-400">
              <div className="flex flex-col">
                <span className={`text-sm ${isToday ? 'underline' : null}`}>
                  {dayName}
                </span>
                <div
                  onClick={() =>
                    toggleCheckHabit({ id, date: day[0], isChecked })
                  }
                  onMouseEnter={() => setHovered([true, index])}
                  onMouseLeave={() => setHovered([false, null])}
                  className={`text-xs w-10 h-10 rounded-full p-2 my-2 font-semibold flex justify-center items-center cursor-pointer ${
                    isChecked
                      ? `${BG_COLORS_700[color]} text-white`
                      : 'bg-zinc-700 text-zinc-500'
                  }`}>
                  {renderValue()}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex justify-end items-center text-xs text-zinc-500">
        {completionPercentage}% Completed
        {isDevModeEnabled() && (
          <div
            name="calendar-expand"
            className="flex items-center ml-4 hover:text-zinc-400 cursor-pointer">
            <button>
              <CalendarIcon className="w-4 h-4" />
            </button>
          </div>
        )}
        <div
          name="settings"
          ref={settingsRef}
          onClick={() => setShowSettings(!showSettings)}
          className="flex relative items-center ml-4 cursor-pointer">
          <button className="hover:text-zinc-400 ">
            <DotsHorizontalIcon className="w-4 h-4" />
          </button>
          {showSettings && (
            <div
              className="
                absolute top-6 left-0 w-auto z-10 bg-zinc-900 text-xs
                text-left rounded shadow-lg mt-1 m-0 min-w-max bg-clip-padding
                grid grid-cols-1 divide-y divide-dashed divide-zinc-800 border-zinc-800 border
              ">
              <span
                onClick={() => setInEditMode(true)}
                className="py-2 px-4 hover:text-zinc-400">
                Edit
              </span>
              <span
                onClick={() => setShowDeletionConfirmationPopup(true)}
                className="py-2 px-4 text-red-800 hover:text-red-700">
                Delete
              </span>
            </div>
          )}
        </div>
      </div>
      {showDeletionConfirmationPopup && (
        <Popup
          message="Are you sure?"
          accept={{ text: 'Delete', style: 'alert' }}
          cancel={{ text: 'Cancel', style: 'neutral' }}
          onAccept={() => deleteHabitConfirmation(habit.id)}
          onCancel={() => setShowDeletionConfirmationPopup(false)}
        />
      )}
    </div>
  );
}

export default Habit;
