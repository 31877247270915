import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import eventsReducer from 'src/features/eventsSlice';
import habitsReducer from 'src/features/habitsSlice';
import userReducer from 'src/features/userSlice';
import { api } from 'src/services';

export const store = configureStore({
  reducer: {
    events: eventsReducer,
    habits: habitsReducer,
    user: userReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

setupListeners(store.dispatch);
