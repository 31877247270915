import React, { useState } from 'react';

import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useSelector } from 'react-redux';
import { WEEKLY_GOAL_COMPLETION_SCALE } from 'src/constants/colors';
import { getWeekDates, isDevModeEnabled } from 'src/helpers';
import useAppContext from 'src/hooks/useAppContext';

dayjs.extend(weekOfYear);

function MacroView() {
  const AppContext = useAppContext();

  const [showHoverState, setHoverState] = useState(null);
  const habits = useSelector((state) => state.habits.list);

  const { calendar } = AppContext.state;

  const currentYear = dayjs().year();

  /*
    52 weeks in a year
    Week 1:
      should be based off of previous sunday or if Jan 1 is a sunday
      dayjs('2022').day(0) - get sunday for first week of 2022
    Week 52:
      should be based off of the last sunday of the year
      dayjs('2022-12-31').day(0) - get sunday for last week of 2022
    [
      { dateRange: '2021-12-26 - 2022-01-02', habitsChecked: ['id'] },
      { dateRange: '2021-12-26 - 2022-01-02', habitsChecked: ['id'] },
      { dateRange: '2021-12-26 - 2022-01-02', habitsChecked: ['id'] },
    ]
   */
  const constructWeeks = () => {
    const weeks = [];

    for (let i = 1; i <= 53; i++) {
      const weekStart = dayjs(`${currentYear}-01-01`)
        .day(0)
        .add((i - 1) * 7, 'day');
      const weekEnd = dayjs(`${currentYear}-12-31`)
        .day(0)
        .add(i * 7, 'day');

      const weekDatesRange = getWeekDates(weekStart.format('YYYY-MM-DD'));
      const isWeekInFuture = weekStart.isAfter(dayjs());

      // Fetch all habits in a given week
      // Don't perform this check if week is in the future
      const habitsChecked = weekDatesRange.map((date) => {
        if (
          (!isWeekInFuture || isDevModeEnabled()) &&
          calendar[date]?.length > 0
        ) {
          return calendar[date].map((habitData) => habitData.id);
        }

        return [];
      });

      /*
        Naive implementation of calculating habit completion percentage
        in a given week.
        (total habits checked / total habit goals) = percentage
      */
      const calculateRatio = () => {
        if (isWeekInFuture && !isDevModeEnabled()) return [0, 0, 0];

        const totalHabitGoals = habits.reduce(
          (acc, habit) => acc + habit.frequency,
          0,
        );

        const totalHabitsCheckedInWeek = habitsChecked.reduce(
          (acc, habitsCheckedInDay) => acc + habitsCheckedInDay.length,
          0,
        );

        return [
          totalHabitsCheckedInWeek / totalHabitGoals,
          totalHabitGoals,
          totalHabitsCheckedInWeek,
        ];
      };

      const [ratio, habitGoalsCount, habitsCheckedCount] = calculateRatio();

      const week = {
        dateRange: `${weekStart.format('MMM D')} - ${weekEnd.format('MMM D')}`,
        habitsChecked,
        ratio,
        goalsCount: habitGoalsCount,
        habitsCheckedCount: habitsCheckedCount,
      };

      weeks.push(week);
    }

    return weeks;
  };

  const weeksInYear = constructWeeks();
  const completionColor = (ratio) => {
    const colorIndex = ratio;

    if (!colorIndex) {
      return WEEKLY_GOAL_COMPLETION_SCALE[0];
    }

    if (colorIndex > 0 && colorIndex <= 0.15) {
      return WEEKLY_GOAL_COMPLETION_SCALE[0.15];
    }

    if (colorIndex > 0.15 && colorIndex <= 0.3) {
      return WEEKLY_GOAL_COMPLETION_SCALE[0.3];
    }

    if (colorIndex > 0.3 && colorIndex <= 0.45) {
      return WEEKLY_GOAL_COMPLETION_SCALE[0.45];
    }

    if (colorIndex > 0.45 && colorIndex <= 0.6) {
      return WEEKLY_GOAL_COMPLETION_SCALE[0.6];
    }

    if (colorIndex > 0.6 && colorIndex <= 0.75) {
      return WEEKLY_GOAL_COMPLETION_SCALE[0.75];
    }

    if (colorIndex > 0.75 && colorIndex <= 0.9) {
      return WEEKLY_GOAL_COMPLETION_SCALE[0.9];
    }

    if (colorIndex > 0.9) {
      return WEEKLY_GOAL_COMPLETION_SCALE[1];
    }
  };

  return (
    <div>
      {habits.length > 0 ? (
        <>
          <h1 className="my-2 ml-2 font-bold text-neutral-400">
            {currentYear}
          </h1>
          <div className="flex flex-row flex-wrap justify-start">
            {weeksInYear.map((week) => {
              const color = completionColor(week.ratio);
              const hasWeekRatio = week.ratio > 0;
              const weekRatioPercent = `${week.ratio * 100}%`;
              return (
                <div
                  key={week.dateRange}
                  onMouseEnter={() => setHoverState(week.dateRange)}
                  onMouseLeave={() => setHoverState(null)}
                  className={`w-16 h-4 ${color} my-2 ml-2 rounded relative`}>
                  {showHoverState === week.dateRange && (
                    <div
                      className={`
                        z-10 bg-zinc-900 opacity-90 text-xs text-center
                        text-white -top-10 ${
                          hasWeekRatio ? '-left-20' : '-left-4'
                        } whitespace-nowrap absolute
                        w-auto p-2 rounded transition ease-in-out duration-150
                        border border-zinc-800
                    `}>
                      <span>
                        {hasWeekRatio && (
                          <>
                            <strong>{weekRatioPercent}</strong> completed
                            between{' '}
                          </>
                        )}
                        {week.dateRange}
                      </span>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div className="flex flex-col justify-center items-center">
          <h1 className="text-neutral-400">
            Add a habit and track your progress over time.
          </h1>
        </div>
      )}
    </div>
  );
}

export default MacroView;
