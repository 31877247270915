const validation = ({ name, frequency }) => {
  const errors = {};
  if (!name) {
    errors.name = 'Name is required';
  }

  if (!frequency) {
    errors.frequency = 'Frequency is required';
  } else if (frequency < 1 || frequency > 7) {
    errors.frequency = 'Invalid frequency';
  }

  return errors;
};

export const ValidationErrorMessage = ({ message }) => {
  return <div className="text-sm text-red-700">{message}</div>;
};

export default validation;
