import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { clearUser } from 'src/features/userSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  credentials: 'include',
});

const baseQueryWithInterceptors = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    api.dispatch(clearUser());
    return (window.location = '/login');
  }

  return result;
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithInterceptors,
  tagTypes: ['User', 'Habit'],
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query(body) {
        return {
          url: '/users/login',
          method: 'POST',
          body: {
            email: body.email,
            password: body.password,
          },
        };
      },
    }),
    signupUser: builder.mutation({
      query(body) {
        return {
          url: '/users/signup',
          method: 'POST',
          body: {
            email: body.email,
            password: body.password,
          },
        };
      },
    }),
    logoutUser: builder.mutation({
      query() {
        return {
          url: '/users/logout',
          method: 'POST',
          body: {},
        };
      },
    }),
    getHabitsForUser: builder.query({
      query: () => '/habits',
      providesTags: ['Habit'],
    }),
    createHabit: builder.mutation({
      query(body) {
        return {
          url: '/habits/create',
          method: 'POST',
          body: {
            name: body.name,
            frequency: body.frequency,
            color: body.color,
            order: body.order,
          },
        };
      },
      onQueryStarted(patch, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData(
            'getHabitsForUser',
            undefined,
            (cachedHabits) => {
              cachedHabits.push(patch);
            },
          ),
        );

        queryFulfilled.catch(patchResult.undo());
      },
    }),
    updateHabit: builder.mutation({
      query(body) {
        const id = body.id;
        return {
          url: `/habits/${id}/update`,
          method: 'POST',
          body: {
            name: body.name,
            frequency: body.frequency,
            color: body.color,
            order: body.order,
          },
        };
      },
    }),
    deleteHabit: builder.mutation({
      query(body) {
        const id = body.id;
        return {
          url: `/habits/${id}/delete`,
          method: 'delete',
        };
      },
    }),
    getEvents: builder.query({
      query: () => '/events',
    }),
    createEvent: builder.mutation({
      query(body) {
        const { date, id } = body;
        return {
          url: '/events',
          method: 'POST',
          body: {
            date,
            habitId: id,
          },
        };
      },
    }),
    deleteEvent: builder.mutation({
      query(body) {
        const { date, id } = body;
        return {
          url: `/events/${id}`,
          method: 'DELETE',
          body: { date },
        };
      },
    }),
  }),
});

export const {
  useLoginUserMutation,
  useSignupUserMutation,
  useLogoutUserMutation,
  useGetHabitsForUserQuery,
  useUpdateHabitMutation,
  useCreateHabitMutation,
  useDeleteHabitMutation,
  useGetEventsQuery,
  useCreateEventMutation,
  useDeleteEventMutation,
} = api;
