import http from 'src/api/client';

const WEEKS_IN_MONTH = 4;

const generateRandomHabitId = () => String(Date.now());

/*
	Schema:
	{
		calendar: {
      [date]: [{ id, notes?: '' }],
      ...
    },
		habits: [
			{
				id: habitId,
				name: '',
				frequencty: 1
				color: ''
			}
		]
*/
export const createHabit =
  ({ name = '', frequency = 1, color = '' }) =>
  (state, setState) => {
    const uuid = generateRandomHabitId();
    const numberFrequency = parseInt(frequency, 10);

    const habit = {
      id: uuid,
      name,
      frequency: numberFrequency,
      monthlyGoal: numberFrequency * WEEKS_IN_MONTH,
      color,
    };

    state.habits.push(habit);

    return setState(state);
  };

export const updateHabit =
  ({ id, name, frequency, color }) =>
  (state, setState) => {
    const habit = state.habits.find((habit) => habit.id === id);
    const habitIndex = state.habits.indexOf(habit);
    const updatedHabits = [...state.habits];
    const numberFrequency = parseInt(frequency, 10);

    updatedHabits[habitIndex] = {
      ...habit,
      name,
      frequency: numberFrequency,
      color,
    };

    return setState({
      ...state,
      habits: updatedHabits,
    });
  };

export const removeHabitFromCalendar =
  ({ id }) =>
  (state, setState) => {
    const calendarWithRemovedHabit = () => {
      const { calendar } = state;
      const newCalendar = { ...calendar };

      Object.keys(calendar).forEach((date) => {
        newCalendar[date] = calendar[date].filter((habitId) => habitId !== id);
      });

      return newCalendar;
    };

    const newState = {
      ...state,
      calendar: calendarWithRemovedHabit(),
    };

    return setState(newState);
  };

export const deleteHabit =
  ({ id }) =>
  (state, setState) => {
    const habit = state.habits.find((habit) => habit.id === id);
    const habitIndex = state.habits.indexOf(habit);
    const calendarWithRemovedHabit = () => {
      const { calendar } = state;
      const newCalendar = { ...calendar };

      Object.keys(calendar).forEach((date) => {
        newCalendar[date] = calendar[date].filter((habitId) => habitId !== id);
      });

      return newCalendar;
    };

    const newState = {
      ...state,
      calendar: calendarWithRemovedHabit(),
    };

    newState.habits.splice(habitIndex, 1);

    return setState(newState);
  };

export const reorderHabits =
  ({ sourceIndex, destinationIndex }) =>
  (state, setState) => {
    const habits = state.habits;

    const [removed] = habits.splice(sourceIndex, 1);
    habits.splice(destinationIndex, 0, removed);

    const newState = { ...state, habits };

    return setState(newState);
  };

export const checkHabit =
  ({ id, date }) =>
  (state, setState) => {
    const newState = {
      ...state,
      calendar: {
        ...state.calendar,
        [date]: [...state.calendar[date]].concat([id]),
      },
    };

    return setState(newState);
  };

export const uncheckHabit =
  ({ id, date }) =>
  (state, setState) => {
    const newState = {
      ...state,
      calendar: {
        ...state.calendar,
        [date]: state.calendar[date].filter((habitId) => habitId !== id),
      },
    };

    return setState(newState);
  };

export const testAuth = async () => {
  const data = await http.get('/testAuth');
  return data;
};
