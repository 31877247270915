import { createSlice } from '@reduxjs/toolkit';
import { api } from 'src/services';

const initialState = {
  list: [],
};

export const eventsReducer = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export default eventsReducer;
