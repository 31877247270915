import React from 'react';
import { useEffect } from 'react';

import { Navigate, useLocation } from 'react-router-dom';
import { useLogoutUserMutation } from 'src/services';

const Logout = () => {
  const [logout] = useLogoutUserMutation();
  const location = useLocation();

  useEffect(() => {
    async function logoutUser() {
      await logout();
    }

    logoutUser();

    window.sessionStorage.setItem('isAuthenticated', false);
    window.localStorage.clear();
  }, []);

  return <Navigate to="/login" state={{ from: location }} />;
};

export default Logout;
