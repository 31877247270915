import { createSlice } from '@reduxjs/toolkit';
import { api } from 'src/services';

const initialState = {
  id: '',
  email: '',
  isPremium: false,
  isAuthenticated: false,
};

const setUser = (state, { payload }) => {
  const { id, email } = payload.user;
  state.id = id;
  state.email = email;
  state.isAuthenticated = true;

  window.sessionStorage.setItem('isAuthenticated', true);
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUser: (state) => {
      state.id = '';
      state.email = '';
      state.isAuthenticated = false;

      // clear auth tokens store in cookies
      document.cookie = '';

      window.sessionStorage.setItem('isAuthenticated', false);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.loginUser.matchFulfilled, setUser);
    builder.addMatcher(api.endpoints.signupUser.matchFulfilled, setUser);
  },
});

export const { clearUser } = userSlice.actions;

export default userSlice.reducer;
