import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { useOnClickOutside } from 'src/hooks';

const Popup = ({
  accept = { style: 'ok', text: 'Accept' },
  cancel = { style: 'neutral', text: 'Cancel' },
  message,
  onAccept,
  onCancel,
}) => {
  const buttonStyles = 'rounded w-full py-3 px-8 text-center';
  let acceptButtonClassName = `${buttonStyles} ${
    accept.style === 'ok'
      ? 'bg-green-800 hover:bg-green-900'
      : 'bg-red-800 hover:bg-red-900'
  } text-white ml-2`;
  let cancelButtonClassName = `${buttonStyles} ${
    cancel.style === 'neutral'
      ? 'bg-transparent hover:bg-zinc-800'
      : 'bg-red-800 hover:bg-red-900'
  } text-zinc-500`;

  return (
    <div className="flex absolute top-0 justify-center align-middle">
      <div
        name="overlay"
        className="overflow-hidden fixed top-0 left-0 z-10 w-screen h-screen bg-neutral-900 opacity-80"
      />
      <div
        className="
				z-20 relative inset-0 bg-zinc-900 rounded m-auto mt-[40%] py-6 px-16 text-center
				w-fit max-w-sm flex flex-col border border-zinc-800 
				">
        <span className="font-semibold">{message}</span>
        <div className="flex flex-row justify-between mt-6">
          {cancel && onCancel && (
            <button onClick={onCancel} className={cancelButtonClassName}>
              {cancel.text}
            </button>
          )}
          {accept && onAccept && (
            <button onClick={onAccept} className={acceptButtonClassName}>
              {accept.text}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

Popup.propTypes = {
  accept: PropTypes.shape({
    style: PropTypes.oneOf(['ok', 'alert']),
    text: PropTypes.string,
  }),
  cancel: PropTypes.shape({
    style: PropTypes.oneOf(['neutral', 'alert']),
    text: PropTypes.string,
  }),
  message: PropTypes.string,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
};

export default Popup;
