import './App.css';

import React, { useState, useEffect } from 'react';

import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { init, LOCAL_STORAGE_KEY } from 'src/api';
import { testAuth } from 'src/api/actions';
import { isDevModeEnabled } from 'src/helpers';
import { useLocalStorage } from 'src/hooks';
import Auth from 'src/pages/Auth';
import Home from 'src/pages/Home';
import Logout from 'src/pages/Logout';
import ProtectedRoute from 'src/pages/ProtectedRoute';

export const AppContext = React.createContext();

function App() {
  const [localStorageState, setLocalStorageState] = useLocalStorage(
    LOCAL_STORAGE_KEY,
    {
      habits: [],
      calendar: {},
      isLoggedIn: false,
    },
  );

  useEffect(() => {
    init()(localStorageState, setLocalStorageState);
  }, []);

  const providerValue = {
    state: localStorageState,
    setState: setLocalStorageState,
  };

  // Dev Mode Disable
  const disableDevMode = () => window.__STATE__.devTools.disableDevMode();
  const locallyStoredAuthToken = document.cookie.split('=')[1];

  return (
    <Router>
      <div className="container p-4 my-10 mx-auto text-white App">
        <AppContext.Provider value={providerValue}>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route path="/signup" element={<Auth mode="signup" />} />
            <Route path="/login" element={<Auth mode="login" />} />
            <Route path="/logout" element={<Logout />} />
          </Routes>
        </AppContext.Provider>
      </div>
      {isDevModeEnabled() && (
        <div className="fixed bottom-4 left-4 text-lg font-semibold text-zinc-700">
          <div>
            ⚠️ Dev Mode Enabled
            <button
              onClick={disableDevMode}
              className="py-1 px-2 ml-4 text-xs rounded border border-zinc-700">
              Disable
            </button>
          </div>
          <div className="text-sm">
            Auth Token: {locallyStoredAuthToken || 'None'}
          </div>
          <div>
            <button
              onClick={testAuth}
              className="py-1 px-2 my-2 text-xs rounded border border-zinc-700">
              Test Auth Request
            </button>
          </div>
        </div>
      )}
    </Router>
  );
}

export default App;
