import React, { useEffect, useState } from 'react';

import { PlusCircleIcon } from '@heroicons/react/outline';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import CreateHabitForm from 'src/components/CreateHabitForm';
import Habit from 'src/components/Habit';
import { reorderHabits } from 'src/features/habitsSlice';
import { getWeekDates } from 'src/helpers';
import useAppContext from 'src/hooks/useAppContext';
import { useGetHabitsForUserQuery } from 'src/services';

function HabitsView() {
  const dispatch = useDispatch();

  const [showCreateHabitForm, setShowCreateHabitForm] = useState(false);
  const { data, isLoading } = useGetHabitsForUserQuery();
  const habits = useSelector((state) => state.habits.list);

  const AppState = useAppContext();
  const { calendar } = AppState.state;
  const { state, setState } = AppState;

  useEffect(() => {
    if (!isLoading) {
      if (Object.keys(habits).length === 0) {
        setShowCreateHabitForm(true);
      }
    }
  }, [habits, isLoading]);

  const getDroppableAreaStyle = (isDraggingOver) => ({
    /* Can add draggable area styles here */
  });

  const getDraggableHabitStyle = (isDragging, draggableStyle) => ({
    /* Can add styles here for when a habit is dragged here */
    ...draggableStyle,
  });

  const handleOnDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const sourceIndex = result?.source?.index;
    const destinationIndex = result?.destination?.index;

    dispatch(reorderHabits({ sourceIndex, destinationIndex }));
  };

  return (
    <div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getDroppableAreaStyle(snapshot.isDraggingOver)}>
              {habits.map((habit, index) => {
                const constructWeekData = () => {
                  const currentWeekDates = getWeekDates();
                  return currentWeekDates.map((day) => {
                    const isChecked = calendar[day].includes(habit.id);

                    return [day, isChecked ? 1 : 0];
                  });
                };

                const weekData = constructWeekData();

                return (
                  <Draggable
                    key={habit.id}
                    draggableId={String(habit.id)}
                    index={index}>
                    {(provided, snapshot) => (
                      <Habit
                        innerRef={provided.innerRef}
                        provided={provided}
                        style={getDraggableHabitStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}
                        habit={habit}
                        weekData={weekData}
                      />
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {showCreateHabitForm ? (
        <CreateHabitForm
          onCancel={() => setShowCreateHabitForm(false)}
          order={habits.length}
        />
      ) : (
        <div
          name="new-habit"
          className="justify-center mt-5 w-full text-center text-zinc-500 hover:text-zinc-400 align-middle cursor-pointer"
          onClick={() => setShowCreateHabitForm(true)}>
          <PlusCircleIcon className="inline w-5 h-5" />
          <span className="inline-block ml-2">New Habit</span>
        </div>
      )}
    </div>
  );
}

export default HabitsView;
