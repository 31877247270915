/* Tailwind can't interpolate colors because it doesn't know what to export during build  */
export const BG_COLORS_700 = {
  green: 'bg-green-700',
  blue: 'bg-blue-700',
  purple: 'bg-purple-700',
  orange: 'bg-orange-700',
  yellow: 'bg-yellow-700',
  red: 'bg-red-700',
  zinc: 'bg-zinc-700',
};

export const BORDER_COLORS_900 = {
  green: 'border-green-900',
  blue: 'border-blue-900',
  purple: 'border-purple-900',
  orange: 'border-orange-900',
  yellow: 'border-yellow-900',
  red: 'border-red-900',
};

export const WEEKLY_GOAL_COMPLETION_SCALE = {
  0: 'bg-zinc-800',
  0.15: 'bg-green-200',
  0.3: 'bg-green-300',
  0.45: 'bg-green-400',
  0.6: 'bg-green-500',
  0.75: 'bg-green-600',
  0.9: 'bg-green-700',
  1: 'bg-green-800',
};
