import React from 'react';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from 'src/store';

import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

window.__STATE__ = {
  getState: () => {
    return { ...JSON.parse(window.localStorage.getItem('state') || '{}') };
  },
  devTools: {
    enableDevMode: () => {
      window.sessionStorage.setItem('devMode', true);
      console.log(
        '%cDev mode enabled. Refresh your browser.',
        'color: #00ff00',
      );
    },
    disableDevMode: () => {
      window.sessionStorage.setItem('devMode', false);
      console.log(
        '%cDev mode disabled. Refresh your browser.',
        'color: #ff0000',
      );
    },
    seedData: () => {
      const state = JSON.parse(window.localStorage.getItem('state') || '{}');
      const newState = { ...state };

      const randomHabits = [
        { id: '1', name: 'Read for 20mins', color: 'green', frequency: 7 },
        { id: '2', name: 'Go to the gym', color: 'blue', frequency: 4 },
        { id: '3', name: 'Eat a healthy meal', color: 'yellow', frequency: 2 },
      ];

      newState.habits = randomHabits;

      const getRandomInt = (max) => {
        return Math.floor(Math.random() * max);
      };

      Object.keys(newState.calendar).forEach((day) => {
        const randomInt = getRandomInt(4);

        for (let i = 0; i < 2; i++) {
          if (randomInt === 0) {
            return;
          }

          if (!newState.calendar[day].find((habit) => habit.id === randomInt)) {
            newState.calendar[day].push({ id: String(randomInt) });
          }
        }
      });

      window.localStorage.setItem('state', JSON.stringify(newState));

      console.log(
        `%c State seeded. Enabling Dev Mode is recommended. Refresh your browser.`,
        'color: green',
      );
    },
  },
};
