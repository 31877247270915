import dayjs from 'dayjs';

export const LOCAL_STORAGE_KEY = 'state';

export const writeToLocalStorage = (data) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
};

export const getLocalStorageItems = () => {
  const data = localStorage.getItem(LOCAL_STORAGE_KEY);
  return data ? JSON.parse(data) : null;
};

// Probably don't need this fn anymore, can remove
export const populateCurrentMonthData = () => {
  const data = [];
  const daysInCurrentMonth = dayjs().daysInMonth();

  for (let i = 1; i <= daysInCurrentMonth; i++) {
    const date = dayjs().set('date', i).format('YYYY-MM-DD');
    data.push([date, []]);
  }

  return data;
};

export const populateCurrentYearData = ({
  populatePreviousWeek = false,
  populateEndingWeek = false,
}) => {
  const data = {};
  const DAYS_IN_YEAR = 365;
  const DAYS_IN_WEEK = 7;

  const populateDateData = (index) => {
    const date = dayjs().set('date', index).format('YYYY-MM-DD');
    // Don't overwrite existing data
    if (!data[date]) {
      data[date] = [];
    }
  };

  if (populatePreviousWeek) {
    for (let i = 0; i < DAYS_IN_WEEK; i++) {
      populateDateData(`-${i}`);
    }
  }

  for (let i = 0; i < DAYS_IN_YEAR; i++) {
    populateDateData(i);
  }

  if (populateEndingWeek) {
    for (let i = 0; i < DAYS_IN_WEEK; i++) {
      populateDateData(`${DAYS_IN_YEAR + i}`);
    }
  }

  return data;
};

export const init = () => (localStorageState, setLocalStorageState) => {
  const data = localStorageState;

  if (!Object.keys(data?.calendar).length) {
    const defaultState = {
      calendar: populateCurrentYearData({
        populatePreviousWeek: true,
        populateEndingWeek: true,
      }),
      habits: [],
    };

    setLocalStorageState(defaultState);
  }
};

export const populateLocalStorageStateWithCalendarEvents =
  ({ calendarEvents = [] }) =>
  (localStorageState, setLocalStorageState) => {
    if (calendarEvents.length === 0) return;

    const { calendar } = localStorageState;
    const calendarCopy = { ...calendar };

    for (let i = 0; i < calendarEvents.length; i++) {
      const event = calendarEvents[i];
      const { date, habit_id } = event;

      // prevent addition of event entries if it exists already for the date
      if (calendarCopy[date] && calendarCopy[date].indexOf(habit_id) === -1) {
        calendarCopy[date].push(habit_id);
      }
    }

    setLocalStorageState({ calendar: calendarCopy });
  };
