import React, { useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useLoginUserMutation, useSignupUserMutation } from 'src/services';

function Auth({ mode }) {
  const inputClass = [
    'rounded-md',
    'p-2 bg-zinc-700',
    'text-neutral-400',
    'my-2',
  ].join(' ');

  const navigate = useNavigate();

  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [AuthenticationError, setAuthenticationError] = useState('');

  const [login] = useLoginUserMutation();
  const [signup] = useSignupUserMutation();

  const text = {
    signup: {
      accountOption: 'Already have an account?',
      accountOptionHref: '/login',
      accountOptionLink: 'Log In',
      submitButton: 'Sign Up',
    },
    login: {
      accountOption: "Don't have an account?",
      accountOptionHref: '/signup',
      accountOptionLink: 'Sign Up',
      submitButton: 'Log In',
    },
  };

  const clearValidationErrors = (e) => {
    const inputName = e.target.name;
    if (!validationErrors[inputName]) return;

    const newValidationErrors = { ...validationErrors };
    delete newValidationErrors[inputName];

    setValidationErrors(newValidationErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const email = emailInputRef.current.value;
    const password = passwordInputRef.current.value;

    const errors = {};

    if (!email) {
      errors.email = 'Email is required';
    }

    // if (!password || password.length < 8) {
    //   errors.password = 'Password must be at least 8 characters';
    // }

    if (Object.keys(errors).length > 0) {
      return setValidationErrors(errors);
    }

    const req = await (mode === 'login' ? login : signup)({
      email,
      password,
    });

    if (req?.error) {
      const errorMessage = req.error?.data?.message;
      setAuthenticationError(errorMessage);

      return false;
    }

    return navigate('/');
  };

  return (
    <div>
      <span className="text-xl font-bold text-neutral-100">
        {mode === 'signup'
          ? 'Create your Sequence account'
          : 'Log in to Sequence'}
      </span>
      <div className="p-10 mt-4 bg-zinc-800 rounded-md border-2 border-neutral-900">
        {AuthenticationError ? (
          <div className="p-2 mb-2 w-full bg-yellow-600">
            <span>{AuthenticationError}</span>
          </div>
        ) : null}
        <form className="flex flex-col" onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            ref={emailInputRef}
            placeholder="Email"
            className={inputClass}
            onChange={clearValidationErrors}
          />
          {validationErrors.email}
          <input
            type="password"
            name="password"
            ref={passwordInputRef}
            placeholder="Password"
            className={inputClass}
            onChange={clearValidationErrors}
          />
          {validationErrors.password}
          <button
            type="submit"
            className="py-2 mt-2 font-bold bg-blue-600 rounded-md">
            {text[mode].submitButton}
          </button>
        </form>
      </div>
      <p className="mt-4 text-center text-neutral-400">
        {text[mode].accountOption}{' '}
        <a
          href={text[mode].accountOptionHref}
          className="text-blue-600 underline">
          {text[mode].accountOptionLink}
        </a>
      </p>
    </div>
  );
}

export default Auth;
